jQuery(document).ready(function($) {
    if ($('#member_dir_search').length) {

        var app = new Vue({
            el: '#member_dir_search',
            data: {
                rawCompanies: [],
                companyType: '',
                searchString: '',
            },
            computed: {
                companies: function() {
                    let filteredMembers = this.rawCompanies;
                    
                    // if memberTypes is set filter the raw data by membership type
                    if ( this.companyType !== '') {
                        filteredMembers = filteredMembers.filter(company => {
                            return company.member_type === this.companyType
                        });
                    }
                        
                    // if search string has a length > than 1 further filter by company name 
                    if (this.searchString.length > 1 ) {
                        filteredMembers = filteredMembers.filter(company => {
                            // includes is case sensitive so we user .toLowerCase()
                            return company.company_name.toLowerCase().includes(this.searchString.toLowerCase())
                        });
                    }

                    return filteredMembers;
                }
            },
            created() {
                this.getCompanies();
            },
            methods: {
                getCompanies() {
                    $.ajax({
                        url: '/wp-json/custom-routes/v1/members',
                        error = (res) => {
                            console.log(res);
                        },
                        success = (res) => {
                            this.rawCompanies = res;
                        }
                    });
                },
                setCompanyType(type) {
                    // if the selected type is already selected unset companyType else set companyType
                    if (type === this.companyType) {
                        this.companyType = '';
                    } else {
                        this.companyType = type;
                    }
                }
            }
        });
    }
});
jQuery(document).ready(function ($) {
    $('.benefits ul li').hover(
        function () {
            $(this).addClass('active');
        },
        function () {
            $(this).removeClass('active');
        }
    );

    $('.benefits ul li').click(function () {
        $('.benefit_info').removeClass('active');
        let id = $(this).data('info-id');
        $('#' + id).addClass('active');
    });

    $('.benefit_info svg').click(function () {
        $(this).parent('.benefit_info').removeClass('active');
    });

    $('.pricing ul li').click(function () {
        $('.pricing ul li').removeClass('active');
        $(this).addClass('active');
        localStorage.setItem('gwp', $(this).data('gwp'));
    });

    $('.membership_option a').click(function (e) {
        e.preventDefault();
        let anchor = $(this);
        let href = anchor.attr('href');
        let membership = anchor.data('membership');

        localStorage.setItem('membership_type', membership);
        window.location.href = href;

        // // Retrieve
        // localStorage.getItem("lastname");
    });
    $('.join-3 a').click(function (e) {
        e.preventDefault();
        let anchor = $(this);
        let href = anchor.attr('href');
        let membership = anchor.data('membership');

        localStorage.setItem('membership_type', membership);
        window.location.href = href;

        // // Retrieve
        // localStorage.getItem("lastname");
    });
});
jQuery(document).ready(function ($) {
    $('#offers-submit').click(function () {
        let editorContent = tinymce.get("offers-editor").getContent();
        let active = 0;

        if ($('#offer-activation').is(':checked')) {
            active = 1;
        }

        $.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                activated: active,
                offers: editorContent,
                action: 'updateMemberOffers'
            },
            error: function (res) {
                console.log(res);
            },
            success: function (res) {
                // console.log(res);
            }
        });
    });

    tinymce.init({
        selector: '#offers-editor',
        plugins: 'link list',
        toolbar: 'undo redo | styleselect | bullist numlist | bold italic underline | alignleft | aligncenter | alignright | link',
        style_formats: [{
                title: 'Heading 1',
                format: 'h2'
            },
            {
                title: 'Heading 2',
                format: 'h3'
            },
            {
                title: 'Heading 3',
                format: 'h4'
            },
            {
                title: 'Heading 4',
                format: 'h5'
            },
            {
                title: 'Heading 5',
                format: 'h6'
            },
            {
                title: 'Paragraph',
                format: 'p'
            }
        ],
        menubar: false,
        relative_urls : false,
        convert_urls : false,
        setup: function (editor) {
            editor.on('init', function (e) {
                $.ajax({
                    url: ajax_url,
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        action: 'getMemberOffers'
                    },
                    error: function (res) {
                        console.log(res);
                    },
                    success: function (res) {
                        // console.log(res.status);
                        editor.setContent(res.text);

                        if (res.status === '1') {
                            $('#offer-activation').prop("checked", true);
                        }
                    }
                });
            });
        }
    });

    $('#bio-submit').click(function () {
        let editorContent = tinymce.get("bio-editor").getContent();
        this.successmessage ="Details updated";
        $.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                companyBio: editorContent,
                action: 'updateCompanyBio'
            },
            error: function (res) {
                console.log(res);
            },
            success: function (res) {
                console.log(res);
            }
        });
        setTimeout(() => {
            this.successmessage ="";
        }, 1000);
    });

    tinymce.init({
        selector: '#bio-editor',
        plugins: 'link list',
        toolbar: 'undo redo | styleselect | bullist numlist | bold italic underline | alignleft | aligncenter | alignright | link',
        style_formats: [{
                title: 'Heading 1',
                format: 'h2'
            },
            {
                title: 'Heading 2',
                format: 'h3'
            },
            {
                title: 'Heading 3',
                format: 'h4'
            },
            {
                title: 'Heading 4',
                format: 'h5'
            },
            {
                title: 'Heading 5',
                format: 'h6'
            },
            {
                title: 'Paragraph',
                format: 'p'
            }
        ],
        menubar: false,
        relative_urls : false,
        convert_urls : false,
        setup: function (editor) {
            editor.on('init', function (e) {
                $.ajax({
                    url: ajax_url,
                    type: 'GET',
                    // dataType: 'html',
                    data: {
                        action: 'getCompanyBio'
                    },
                    error: function (res) {
                        console.log(res);
                    },
                    success: function (res) {
                        // console.log(res);
                        editor.setContent(res);
                    }
                });
            });
        }
    });

    $(document).on('submit', '#company-logo-form', function (e) {
        // $('#company-logo-form').on('submit', function(e) {
        e.preventDefault();
        $('#img-upload-error').text("");

        let fileData = $(this).find('input[type=file]').prop('files')[0];
        let maxSize = 1048576; // 1mb

        if (fileData.type == "image/png" || fileData.type == "image/jpeg" || fileData.type == "image/svg+xml") {
            if (fileData.size < maxSize) {
                let formData = new FormData();

                formData.append('logo_img_file', fileData);
                formData.append('action', 'uploadCompanyLogo');

                $.ajax({
                    url: ajax_url,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    dataType: 'json',
                    data: formData,
                    error: function (res) {
                        console.log(res);
                    },
                    success: function (res) {
                        // console.log(res);
                        if (res.error) {
                            $('#img-upload-error').text(res.error);
                        } else {
                            // replace upload form with delete section
                            let html = '' +
                                '<p id="img-delete-error"></p>' +
                                res.image +
                                '<button type="button" class="btn bg green" id="delete-logo">Delete Logo</button>';

                            $('#company-logo-section .body').html(html);
                        }
                    }
                });
            } else {
                $('#img-upload-error').text("ERROR: Max file size is 1mb.");
            }
        } else {
            $('#img-upload-error').text("ERROR: Accepted file formats are png, jpeg & svg.");
        }
    });

    $(document).on('click', '#delete-logo', function () {
        // $('#delete-logo').click( function() {
        $.ajax({
            url: ajax_url,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'deleteCompanyLogo'
            },
            error: function (res) {
                console.log(res);
            },
            success: function (res) {
                // console.log(res);
                if (res.success == false) {
                    $('#img-delete-error').text(res.error);
                } else {
                    // swap the form to the upload image form
                    let html = '' +
                        '<form id="company-logo-form" method="post" enctype="multipart/form-data">' +
                        '<p id="img-upload-error"></p>' +
                        '<input type="file" name="company_logo" multiple="false"  accept="image/png, image/jpeg, image/svg+xml" />' +
                        '<input class="btn bg green" name="upload_company_logo" type="submit" value="Upload Image" />' +
                        '</form>';

                    $('#company-logo-section .body').html(html);
                }
            }
        })
    });

    // var media_uploader = null;

    // function open_media_uploader_video() {
    //     media_uploader =  wp.media({
    //         title: 'Upload Your Organisation Logo',
    //         button: {
    //           text: 'Upload Logo'
    //         },
    //         library: {
    //             type: 'image'
    //         },
    //         multiple: false  // Set to true to allow multiple files to be selected
    //     });

    //     // media_uploader.on("update", function(){

    //     //     var extension = media_uploader.state().media.extension;
    //     //     var video_url = media_uploader.state().media.attachment.changed.url;
    //     //     var video_icon = media_uploader.state().media.attachment.changed.icon;
    //     //     var video_title = media_uploader.state().media.attachment.changed.title;
    //     //     var video_desc = media_uploader.state().media.attachment.changed.description;
    //     // });

    //     media_uploader.open();
    // }

    // if ($('#test-media').length) {
    //     open_media_uploader_video();
    // }


    if ($('#company_settings_form').length) {
        var app = new Vue({
            el: '#company_settings_form',
            data: {
                form: {},
                introductionProvider: false,
                businessLineOptions: Array(),
                errors: {
                    msg: [],
                    bool: {},
                },
                successmessage:"",
                businessLine: '',
                businessLineInsurer: '',
                businessLineYears: '',
            },
            computed: {
                mgaMembership: function () {
                    if (this.form.companyData.membershipType === 'MGA Member') {
                        return true;
                    } else {
                        return false;
                    }
                },
                supplierMembership: function () {
                    if (this.form.companyData.membershipType === 'Supplier') {
                        return true;
                    } else {
                        return false;
                    }
                },
                marketPractitionerMembership: function () {
                    if (this.form.companyData.membershipType === 'Market Practitioner') {
                        return true;
                    } else {
                        return false;
                    }
                },
                vailidBusinessLine: function () {
                    if (this.businessLine !== '') {
                        return true;
                    }

                    return false;
                },
                roi: function () {
                    if (this.form.companyData.paymentcountry == 'IR') {
                        return true;
                    }

                    return false;
                },
                
          
            },
            watch: {
                'form.member.gwpAfrica': function () {
                    if(this.form.member.gwpAfrica!=""){
                    this.form.member.gwpAfrica = this.form.member.gwpAfrica.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                }},
                'form.member.gwpAsia': function () {
                    this.form.member.gwpAsia = this.form.member.gwpAsia.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpEurope': function () {
                    this.form.member.gwpEurope = this.form.member.gwpEurope.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpNorthAmerica': function () {
                    this.form.member.gwpNorthAmerica = this.form.member.gwpNorthAmerica.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpProi': function () {
                    this.form.member.gwpProi = this.form.member.gwpProi.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpUk': function () {
                    this.form.member.gwpUk = this.form.member.gwpUk.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpUsa': function () {
                    this.form.member.gwpUsa = this.form.member.gwpUsa.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                }
            },
            created() {
                this.getPreFilledData();
           
          

            },
            methods: {

                getPreFilledData() {
                    $.ajax({
                        url: ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'preFilledData'
                        },
                        error: function (res) {
                            console.log(res);
                        },
                        success = (res) => {
                            console.log(res);
                            this.form = res;

                            if (res.member.principal !== '') {
                                this.authorisedRep = true;
                            }

                            if (res.member.auditAmount !== '') {
                                this.audited = true;
                            }

                            if (res.member.introduction !== '') {
                                this.introductionProvider = true;
                            }

                            if (res.companyData.membershipType === 'MGA Member') {
                                this.getLinesOfBusiness();
                            }
                        }
                    });
                },
                getLinesOfBusiness() {
                    $.ajax({
                        url: '/wp-json/custom-routes/v1/product-types',
                        error = (res) => {
                            console.log(res);
                        },
                        success = (res) => {
                            this.businessLineOptions = res;
                        }
                    });
                },
                addBusinessLine() {
                    if (this.businessLine !== '') {
                        let selected = this.businessLineOptions[this.businessLine];

                        this.form.member.linesOfBusiness.push({
                            id: selected.product_id,
                            name: selected.product_name,
                            insurer: this.businessLineInsurer,
                            years: this.businessLineYears
                        });

                        this.businessLineInsurer = "";
                        this.businessLineYears = "";
                    }
                },
                addDirector(event) {
                    let value = $('.director-input').val();
                    console.log(value);
                    if (!this.form.member.directors.includes(value)) {
                        this.form.member.directors.push(value);
                    }
                },
                removeDirectors(index) {
                    this.form.member.directors.splice(index, 1);
                },
                removeBusinessLine(index) {
                    this.form.member.linesOfBusiness.splice(index, 1);
                },
                addOtherUnderwitingSystem(event) {
                    let value = $('.other-underwriting-input').val();
                    console.log(value);
                    if (!this.form.member.underwritingSystem.includes(value)) {
                        this.form.member.underwritingSystem.push(value);
                    }
                },
                addUnderwitingSystem(event) {
                    let value = event.target.value;

                    if (!this.form.member.underwritingSystem.includes(value)) {
                        this.form.member.underwritingSystem.push(value);
                    }
                },
                removeUnderwritingSystem(index) {
                    this.form.member.underwritingSystem.splice(index, 1);
                },
                companySettingsSubmit() {
                    this.validateForm();
                    this.successmessage ="";
                    if (this.errors.msg.length <= 0) {
                        this.successmessage ="Details updated";
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                formData: this.form,
                                action: 'dashboardUpdateCompanyDetails'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success = (res) => {
                                // console.log(res);
                              
                            }
                        });
                    } else {
                        this.scrollToErrors();
                    }
                    setTimeout(() => {
                        this.successmessage ="";
                    }, 1000);
                },
                validateForm() {
                    this.errors.msg = [];
                    this.errors.bool = {};

                    if (!this.form.companyData.companyName) {
                        this.errors.msg.push('Organisation Name required.');
                        this.errors.bool['companyName'] = true;
                    }

                    if (!this.form.companyData.addressLineOne) {
                        this.errors.msg.push('Address Line 1 required.');
                        this.errors.bool['addressLineOne'] = true;
                    }

                    if (!this.form.companyData.town) {
                        this.errors.msg.push('Town required.');
                        this.errors.bool['town'] = true;
                    }

                    if (!this.form.companyData.county) {
                        this.errors.msg.push('County required.');
                        this.errors.bool['county'] = true;
                    }

                    if (!this.form.companyData.postcode) {
                        this.errors.msg.push('Postcode required.');
                        this.errors.bool['postcode'] = true;
                    }

                    if (!this.form.companyData.country) {
                        this.errors.msg.push('Country Name required.');
                        this.errors.bool['country'] = true;
                    }

                    if (!this.form.companyData.companyPhoneNumber) {
                        this.errors.msg.push('Company Telephone Number required.');
                        this.errors.bool['companyPhoneNumber'] = true;
                    }
     

                    // if company has another registered address
                    if (this.form.companyData.differentRegAddress === '1') {
                        if (!this.form.companyData.regAddressLineOne) {
                            this.errors.msg.push('Company Registered Address Line 1 required.');
                            this.errors.bool['regAddressLineOne'] = true;
                        }

                        if (!this.form.companyData.regTown) {
                            this.errors.msg.push('Company Registered Town required.');
                            this.errors.bool['regTown'] = true;
                        }

                        if (!this.form.companyData.regCounty) {
                            this.errors.msg.push('Company Registered County required.');
                            this.errors.bool['regCounty'] = true;
                        }

                        if (!this.form.companyData.regPostcode) {
                            this.errors.msg.push('Company Registered Postcode required.');
                            this.errors.bool['regPostcode'] = true;
                        }

                        if (!this.form.companyData.regCountry) {
                            this.errors.msg.push('Company Registered Country Name required.');
                            this.errors.bool['regCountry'] = true;
                        }
                    }

                    if (this.mgaMembership) {

                        if (!this.form.member.tobaUpdate) {
                            this.errors.msg.push('How often do you update your TOBA required.');
                            this.errors.bool['tobaUpdate'] = true;
                        }
                        if (this.form.member.tobaOutsource =="") {
                            this.errors.msg.push('Do you outsource your TOBA required.');
                            this.errors.bool['tobaUpdate'] = true;
                        }
                        if (!this.form.companyData.dateEstablished) {
                            this.errors.msg.push('Date Established required.');
                            this.errors.bool['dateEstablished'] = true;
                        }

                        if (!this.form.member.registeredNum) {
                            this.errors.msg.push('Registered Organisation Number required.');
                            this.errors.bool['regNum'] = true;
                        }


                        if (!this.form.member.legalStatus) {
                            this.errors.msg.push('Organisation\'s Legal Status required.');
                            this.errors.bool['legalStatus'] = true;
                        }

                        if (!this.form.member.ownership) {
                            this.errors.msg.push('Organisation\'s Ownership required.');
                            this.errors.bool['organisationOwnership'] = true;
                        }

                        if (!this.form.member.directors) {
                            this.errors.msg.push('Name of Directors required.');
                            this.errors.bool['directorsNames'] = true;
                        }

                        if (!this.form.member.activity) {
                            this.errors.msg.push('Type of Activity required.');
                            this.errors.bool['activityType'] = true;
                        }

                        if (!this.form.member.fcaRef) {
                            this.errors.msg.push('FCA firm reference number required.');
                            this.errors.bool['fcaRef'] = true;
                        }

                        if (!this.form.member.fiduciaryDuty) {
                            this.errors.msg.push('Who do you owe you fiduciary duty to? required.');
                            this.errors.bool['fiduciaryDuty'] = true;
                        }

                        if (this.form.member.authorisedRep === '1') {
                            if (!this.form.member.principal) {
                                this.errors.msg.push('What is the name of your principal? required.');
                                this.errors.bool['principal'] = true;
                            }
                        }

                        if (!this.form.member.distributionModel) {
                            this.errors.msg.push('Distribution Model required.');
                            this.errors.bool['distributionModel'] = true;
                        }

                        if (!this.form.member.producingBrokers) {
                            this.errors.msg.push('How many producing brokers do you have? required.');
                            this.errors.bool['producingBrokers'] = true;
                        }

                        if (!this.form.member.audited) {
                            this.errors.msg.push('When were you last audited? required.');
                            this.errors.bool['audited'] = true;
                        }

                        if (this.form.member.linesOfBusiness.length <= 0) {
                            this.errors.msg.push('Lines of Business required.');
                            this.errors.bool['linesOfBus'] = true;
                        }

                        if (!this.form.member.directorsAmount) {
                            this.errors.msg.push('How many directors? required.');
                            this.errors.bool['directorsAmount'] = true;
                        }

                        if (!this.form.member.underwriters) {
                            this.errors.msg.push('How many underwriters? required.');
                            this.errors.bool['underwriters'] = true;
                        }

                        if (!this.form.member.claimsStaff) {
                            this.errors.msg.push('How many claims staff? required.');
                            this.errors.bool['claimsStaff'] = true;
                        }

                        if (!this.form.member.technicalStaff) {
                            this.errors.msg.push('How many technical IT system related staff? required.');
                            this.errors.bool['itStaff'] = true;
                        }

                        if (!this.form.member.totalStaff) {
                            this.errors.msg.push('Total number of staff including Directors? required.');
                            this.errors.bool['totalStaff'] = true;
                        }
                    }

                    if (this.marketPractitionerMembership) {
                        if (!this.form.member.legalStatus) {
                            this.errors.msg.push('Organisation\'s legal status (eg Ltd, LLP etc) required.');
                            this.errors.bool['legalStatus'] = true;
                        }

                        if (!this.form.member.fcaReference) {
                            this.errors.msg.push('FCA firm reference number required.');
                            this.errors.bool['fcaRef'] = true;
                        }

                        if (!this.form.member.delegatedAuthorityYears) {
                            this.errors.msg.push('How many years have you been involved in MGA Delegated Authority business? required.');
                            this.errors.bool['mgaDelegatedAuth'] = true;
                        }

                        if (this.form.member.introduction === '1') {
                            if (!this.form.member.introducing) {
                                this.errors.msg.push('Name of MGAs that you can provide an introduction for required.');
                                this.errors.bool['introNames'] = true;
                            }
                        }
                    }

                    if (this.supplierMembership) {
                        if (!this.form.companyData.dateEstablished) {
                            this.errors.msg.push('Date Established required.');
                            this.errors.bool['dateEstablished'] = true;
                        }

                        if (!this.form.member.bespoke) {
                            this.errors.msg.push('Please describe the bespoke products and/or services you wish to promote to the MGAA membership. Required.');
                            this.errors.bool['bespoke'] = true;
                        }

                        if (!this.form.member.benefits) {
                            this.errors.msg.push('How do your Organisation\'s products and/or services specifically benefit MGAs? Required.');
                            this.errors.bool['benefit'] = true;
                        }

                        if (!this.form.member.mgaPartners) {
                            this.errors.msg.push('Name the MGAs you are currently working with. Required.');
                            this.errors.bool['partners'] = true;
                        }
                    }
                },
                scrollToErrors() {
                    let headerHeight = $('#header').height();
                    $('html, body').animate({
                        scrollTop: $("#company_settings_form").offset().top - headerHeight
                    }, 1000);
                }
            }
        });
    }
});
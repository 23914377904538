jQuery(document).ready(function ($) {
    //     $.ajax({
    //         url: 'https://api.xero.com/connections',
    //         error = (res) => {
    //             console.log(res);
    //         },
    //         success = (res) => {
    //             console.log(res);
    //         }
    //     });
    // console.log("auth_test.js connected");
    // document.getElementById("make-request").onclick = makeRequest;
    // function makeRequest() {
    //     console.log("makeRequest()");
    //     fetch("post_to_xero.php", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded"
    //         }
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             console.log(data);
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //         });
    // }
});

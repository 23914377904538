jQuery(document).ready(function ($) {
    if (('#map').length) {
        let locations = [];
        let locationsSupplier = [];
        let locationsMarketPractitioner = [];
        let mgaaOn = 1;
        let supplierOn = 1;
        let marketPractitionerOn = 1;
        let markers = [];
        let geocoder;
        let map;
        let bounds = new google.maps.LatLngBounds();




        //get companys
        $.ajax({
            url: '/wp-json/custom-routes/v1/members',
            error: function (response) {
                console.log(response);
            },
            success: function (response) {
                //brake up comapnys in to different arraysa based on member type; 
                for (var i = 0; i < response.length; i++) {
                    if (response[i].member_type == "Supplier") {
                        locationsSupplier.push([response[i].company_name, response[i].postcode, response[i].site_url, response[i].companyid, response[i].companies_map_results, response[i].logo]);

                    } else if (response[i].member_type == "Market Practitioner") {
                        locationsMarketPractitioner.push([response[i].company_name, response[i].postcode, response[i].site_url, response[i].companyid, response[i].companies_map_results, response[i].logo]);

                    } else {
                        locations.push([response[i].company_name, response[i].postcode, response[i].site_url, response[i].companyid, response[i].companies_map_results, response[i].logo]);
                    }
                }
                //initialise google map
                initialize();
            }
        });

        //initialise google map
        function initialize() {
            map = new google.maps.Map(
                document.getElementById("map_canvas"), {
                    center: new google.maps.LatLng( 51.509865, -0.118092),
                    zoom: 13,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                });
            geocoder = new google.maps.Geocoder();
        //updatemap with company data from arrays

            updatemap();
        //remoce 'none' class from buttons so they become visable

            $('.none').removeClass('none');
        }

        // toggles to show differnt company types and update maps on button press
        $(".mgaa").click(function () {
            indexloacation = 0;
            mgaaOn ? mgaaOn = 0 : mgaaOn = 1;
            updatemap();
        })

        $(".supplier").click(function () {
            indexloacation = 0;
            supplierOn ? supplierOn = 0 : supplierOn = 1;
            updatemap();
        })
        $(".marketPractitioner").click(function () {
            indexloacation = 0;
            marketPractitionerOn ? marketPractitionerOn = 0 : marketPractitionerOn = 1;
            updatemap();
        })
        // remove all current markers from map
        function setMapOnAll(map) {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(map);
            }
            markers = [];   
                  
        }

        let updatemap = () => {
            // remove all current markers from map
            if (markers.length > 0) {
                setMapOnAll(null);
            }
            // reset map size
            bounds = new google.maps.LatLngBounds();
            // chack which toggles are on and place markers for those toggles
            if (mgaaOn) {
                mgaa();
                $('.mgaa').addClass('green');
            } else {
                $('.mgaa').removeClass('green');
            }
            if (supplierOn) {
                Supplier();
                $('.supplier').addClass('green');
            } else {
                $('.supplier').removeClass('green');
            }
            if (marketPractitionerOn) {
                MarketPractitioner();
                $('.marketPractitioner').addClass('green');
            } else {
                $('.marketPractitioner').removeClass('green');
            }

        }
        // set icon colors and place markers
        let icon = 'http://maps.google.com/mapfiles/ms/icons/blue.png';

        function mgaa() {
            icon = 'https://www.mgaa.co.uk/wp-content/themes/MGAA_theme/images/lightblue.png';
            for (var i = 0; i < locations.length; i++) {
                geocodeAddress(locations, i, icon);
            }
        }

        function Supplier() {
            icon = 'https://www.mgaa.co.uk/wp-content/themes/MGAA_theme/images/purp.png';
            for (var i = 0; i < locationsSupplier.length; i++) {
                geocodeAddress(locationsSupplier, i, icon);
            }
        }

        function MarketPractitioner() {
            icon = 'https://www.mgaa.co.uk/wp-content/themes/MGAA_theme/images/orange.png';
            for (var i = 0; i < locationsMarketPractitioner.length; i++) {
                geocodeAddress(locationsMarketPractitioner, i, icon);
            }
        }

        function geocodeAddress(locations, i, thisicon) {
            // get data from locations array set in the ajax function at the top of page.
            let title = locations[i][0];
            let address = locations[i][1];
            let url = locations[i][2];
            url = 'https://www.mgaa.co.uk/membership/membership-directory/' + url;
            let id = locations[i][3];
            let mapResults = locations[i][4];
            // check if the compant has mapdata already set
            //if it does place those markers if not call geocoder to get marker placment and save data to sql
            if (mapResults !== "") {
                let jsonparsemap = JSON.parse(mapResults);
                var marker = new google.maps.Marker({
                    icon: thisicon,
                    map: map,
                    position: jsonparsemap,
                    title: title,
                    animation: google.maps.Animation.DROP,
                    address: address,
                    url: url
                });
                markers.push(marker);

                infoWindow(marker, map, title, address, url);
                bounds.extend(marker.getPosition());
                map.fitBounds(bounds);

            } else {
                // console.log(`address:${address}`);
                // console.log(`title:${title}`);

                geocoder.geocode({
                        'address': address
                    },

                    function (results, status) {
                        // console.log(`res=${results} status${status}`)
                        let thisLocation = results[0].geometry.location;
                        var myJSON = JSON.stringify(thisLocation);
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                resultsName: id,
                                resultsData: myJSON,
                                action: 'addToDatabase'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success = (res) => {
                                console.log(res);
                                this.form = res;
                            }
                        });

                        if (status == google.maps.GeocoderStatus.OK) {
                            var marker = new google.maps.Marker({
                                icon: thisicon,
                                map: map,
                                position: thisLocation,
                                title: title,
                                animation: google.maps.Animation.DROP,
                                address: address,
                                url: url
                            })
                            markers.push(marker);
                            infoWindow(marker, map, title, address, url);
                            bounds.extend(marker.getPosition());
                            map.fitBounds(bounds);
                        } 
                    });
            }
            
        }
        // data shown when a marker is clicked on
        function infoWindow(marker, map, title, address, url) {
            google.maps.event.addListener(marker, 'click', function () {
                var html = "<div><h3>" + title + "</h3><p>" + address + "<br></div><a href='" + url + "'>View location</a></p></div>";
                iw = new google.maps.InfoWindow({
                    content: html,
                    maxWidth: 350
                });
                iw.open(map, marker);
            });
        }

        function createMarker(results) {
            var marker = new google.maps.Marker({
                icon: 'http://maps.google.com/mapfiles/ms/icons/blue.png',
                map: map,
                position: results[0].geometry.location,
                title: title,
                animation: google.maps.Animation.DROP,
                address: address,
                url: url
            })
            bounds.extend(marker.getPosition());
            map.fitBounds(bounds);
            infoWindow(marker, map, title, address, url);
            return marker;
        }
    }
})
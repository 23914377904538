jQuery(document).ready(function ($) {
    if ($('#user-creation-form').length) {
        var app = new Vue({
            el: '#user-creation-form',
            data: {
                form: {
                    firstName: '',
                    lastName: '',
                    company: '',
                    position: '',
                    email: '',
                    telephone: '',
                    mobile: '',
                    pass: '',
                    confirmPass: '',
                    addressLineOne: '',
                    addressLineTwo: '',
                    addressLineThree: '',
                    town: '',
                    county: '',
                    postcode: '',
                    country: '',
                    personalLines: '',
                    claimsForum: '',
                    financeContact: '',
                    nextGen: '',
                    riskManagement: '',
                    underwriting: '',
                    mgaMarketingContact: '',
                    mgaLearningDevelopment: '',
                    complianceForumContact: '',
                    eventsTraining: '',
                    insurerContact: '',
                    itOperationsForum: '',
                    brexitForum: '',
                    londonMarketForum: '',

                },
                companies: [],
                suggest: false,
                error: {
                    msg: [],
                    status: {}
                },
                passError: false,
                passConfirmError: false,
                mgaMember: false,
            },
            computed: {
                options: function () {
                    const filteredOptions = new Array();

                    for (let x = 0; x < this.companies.length; x++) {
                        if (this.companies[x][0].toLowerCase().includes(this.form.company.toLowerCase())) {
                            filteredOptions.push(this.companies[x]);
                        }
                    }

                    return filteredOptions;
                }
            },
            created() {
                this.getCompanies();
            },
            methods: {
                getCompanies() {
                    $.ajax({
                        url: '/wp-json/custom-routes/v1/members',
                        error: (res) => {
                            console.log(res);
                        },
                        success: (res) => {
                            let companyNames = [];

                            for (let i = 0; i < res.length; i++) {
                                companyNames.push([res[i].company_name, res[i].member_type, res[i].address_line_1, res[i].address_line_2, res[i].address_line_3, res[i].town, res[i].county, res[i].postcode, res[i].country]);
                                // companyNames.push(res[i].company_name);
                            }

                            companyNames.sort();
                            this.companies = companyNames;
                        }
                    });
                },
                keyActions(event) {
                    let key = event.keyCode;
                    let index = 0; // default select first option
                    let option = $('.option');
                    let activeOption = option.hasClass('active') ? true : false;

                    //if key pressed 40 = down, 38 = up, 13 = enter
                    if (key === 40) {
                        // if there is an action option select next else index = 0
                        if (activeOption) {
                            index = $('.option.active').index() + 1;
                        }
                        option.removeClass('active');
                        option.eq(index).addClass('active');
                    } else if (key === 38) {
                        // if there is an action option select previous else index = 0
                        if (activeOption) {
                            index = $('.option.active').index() - 1;
                        }
                        option.removeClass('active');
                        option.eq(index).addClass('active');
                    } else if (key === 13 && activeOption) {
                        this.form.company = $('.option.active').text().trim();
                        this.suggest = false;
                    }
                },
                clearActiveOption() {
                    $('.option').removeClass('active');
                    this.suggest = true;
                },
                selectOption(option) {
                    this.form.company = option[0];

                    if (option[1] === 'MGA Member') {
                        this.mgaMember = true;
                    } else {
                        this.mgaMember = false;
                    }
                    this.form.addressLineOne = option[2];
                    this.form.addressLineTwo = option[3];
                    this.form.addressLineThree = option[4];
                    this.form.town = option[5];
                    this.form.county = option[6];
                    this.form.postcode = option[7];
                    this.form.country = option[8];

                    this.suggest = false;
                },
                submitForm() {
                    this.validateForm();

                    if (this.error.msg.length === 0) {
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                formData: this.form,
                                action: 'createCompanyUser'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success: (res) => {
                                if (res.successful === 'true') {
                                    window.location.href = res.redirect;
                                } else {
                                    this.error.msg.push(res.error);
                                    this.scrollToErrors();
                                }
                            }
                        });
                    } else {
                        this.scrollToErrors();
                    }
                },
                validateForm() {
                    this.error.msg = [];
                    this.error.status = {};

                    if (!this.form.firstName) {
                        this.error.msg.push('First Name required.');
                        this.error.status['firstName'] = true;
                    }

                    if (!this.form.lastName) {
                        this.error.msg.push('Last Name required.');
                        this.error.status['lastName'] = true;
                    }

                    if (!this.form.company) {
                        this.error.msg.push('Company required.');
                        this.error.status['company'] = true;
                    }

                    if (!this.form.position) {
                        this.error.msg.push('Position required.');
                        this.error.status['position'] = true;
                    }

                    if (!this.form.email) {
                        this.error.msg.push('Email required.');
                        this.error.status['email'] = true;
                    } else if (!this.validEmail(this.form.email)) {
                        this.error.msg.push('Valid email required.');
                        this.error.status['email'] = true;
                    }

                    // if (!this.form.telephone) {
                    //     this.error.msg.push('Telephone Number required.');
                    //     this.error.status['telephone'] = true;
                    // }

                    // if (!this.form.mobile) {
                    //     this.error.msg.push('Mobile Number required.');
                    //     this.error.status['mobile'] = true;
                    // }

                    if (!this.form.pass || !this.form.confirmPass) {
                        this.error.msg.push('Password fields required.');
                        this.error.status['pass'] = true;
                    } else {
                        let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                        if (!re.test(this.form.pass)) {
                            this.error.status['pass'] = true;
                            this.error.msg.push('Passwords must include one uppercase, one lowercase, one digit and at least 8 characters.');
                        } else if (this.form.pass !== this.form.confirmPass) {
                            this.error.msg.push('Passwords must match.');
                            this.error.status['pass'] = true;
                        }
                    }

                    // if (!this.form.addressLineOne) {
                    //     this.error.msg.push('Address Line One required.');
                    //     this.error.status['addLineOne'] = true;
                    // }

                    // if (!this.form.town) {
                    //     this.error.msg.push('Town required.');
                    //     this.error.status['town'] = true;
                    // }

                    // if (!this.form.county) {
                    //     this.error.msg.push('County required.');
                    //     this.error.status['county'] = true;
                    // }

                    // if (!this.form.postcode) {
                    //     this.error.msg.push('Postcode required.');
                    //     this.error.status['postcode'] = true;
                    // }

                    // if (!this.form.country) {
                    //     this.error.msg.push('Country required.');
                    //     this.error.status['country'] = true;
                    // }
                    // if (this.form.termsConditions === '0' && !this.form.reason) {
                    //     this.error.msg.push('Please give a reason for not accepting the terms and conditions.');
                    //     this.error.status['reason'] = true;
                    // }           
                },
                scrollToErrors() {
                    let headerHeight = $('#header').height();
                    $('html, body').animate({
                        scrollTop: $("#user-creation-form").offset().top - headerHeight
                    }, 1000);
                },
                validEmail(email) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                },
                passValidation() {
                    this.passError = false;
                    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                    if (this.form.pass) {
                        if (!re.test(this.form.pass)) {
                            this.passError = true;
                        }
                    }
                },
                confirmPassValidation() {
                    this.passConfirmError = false;

                    if (this.form.pass !== this.form.confirmPass) {
                        this.passConfirmError = true;
                    }
                },
                switchInputFocus() {
                    if (!this.form.pass) {
                        this.$refs.pass.focus();
                    }
                }
            }
        });
    }
});
jQuery(document).ready(function ($) {
    $(".mobile-nav-button").click(function () {
        //open up mobile nax and change butger menu to cross
        $(".mobile-nav").toggleClass("show");
        $(".bar1").toggleClass("change");
        $(".bar2").toggleClass("change");
        $(".bar3").toggleClass("change");
    })
$('#searchbutton').click(function(){
    $('#searchform').addClass('open');
})

    $('#header_nav .menu-item').mouseenter(
        function () {
            //check to see if menu-item is part of a submenu
            if (!$(this).parent().hasClass('sub-menu')) {
                $('.sub-menu-outer-wrap').removeClass('open');
                $(this).find('.sub-menu-outer-wrap').addClass('open');
            }
        }
    );

    const url = window.location.href;
    const splitUrl = url.split("/");

    function checkTitle() {
        for (let item of splitUrl) {
            // console.log(item);
            if (item == "about-us") {
                $('.sub-menu-outer-wrap').removeClass('open');
                $('.menu-item-98').find('.sub-menu-outer-wrap').addClass('open');
            }
            if (item == "join-us") {
                $('.sub-menu-outer-wrap').removeClass('open');
                $('.menu-item-225').find('.sub-menu-outer-wrap').addClass('open');
            }
            if (item == "events" || item == "all-events") {
                $('.sub-menu-outer-wrap').removeClass('open');
                $('.menu-item-100').find('.sub-menu-outer-wrap').addClass('open');
            }
        }
    }
    // checkTitle = () => {
    //     if (document.title == "MGAA Join Us" || document.title == "MGAA MGA Membership" || document.title == "MGAA Market Practitioner" || document.title == "MGAA Supplier" || document.title == "MGAA Membership Directory") {
    //         $('.sub-menu-outer-wrap').removeClass('open');
    //         $('.menu-item-225').find('.sub-menu-outer-wrap').addClass('open');
    //     } else if (document.title == "MGAA About Us" || document.title == "MGAA What we do" || document.title == "MGAA Meet the Board" || document.title == "MGAA Meet the team" || document.title == "MGAA Committees") {
    //         $('.sub-menu-outer-wrap').removeClass('open');
    //         $('.menu-item-98').find('.sub-menu-outer-wrap').addClass('open');
    //     } else {
    //         $('.sub-menu-outer-wrap').removeClass('open');
    //     }
    // }


    checkTitle();
});